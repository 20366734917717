import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { OutOfNetworkImagesQuery } from '../shared/interfaces';

const OutOfNetworkPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<OutOfNetworkImagesQuery>(graphql`
        query {
            outOfNetworkBackgroundImage: file(name: {eq: "outOfNetworkBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            outOfNetworkSummaryImage: file(name: {eq: "outOfNetworkSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Out Of Network"/>
            <GeneralJumbotron
                title="Out Of Network"
                backgroundImageURL={queryResult?.outOfNetworkBackgroundImage.publicURL}
                backgroundText="Out Of Network"
            />
            <SummaryTemplate image={queryResult?.outOfNetworkSummaryImage.publicURL}>
                <p>
                    {`
                        Does your client have a preferred provider, hospital, or
                        surgery center that is not part of the PCH IPA network?
                        No problem! PCH benefits are still available and accessible
                        for out-of-network solutions. Just have your client present
                        their PCH IPA membership card at the time of their
                        appointment, and we will do the rest!
                    `}
                </p>
            </SummaryTemplate>
            <CallToAction/>
        </MainLayout>
    );
}

export default OutOfNetworkPage;
